<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <nb-download :url="ACCOUNT_CSV_URL" :params="condition" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <ns-currency v-model="condition.currency" all-option />
      <n-field-enum v-model="condition.status" enum-name="CustomerAccountStatus" label="status" all-option />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-id v-model="condition.id" label="accountId" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="company">{{ $t('label.company') }}</th>
            <th>{{ $t('label.customerId') }}</th>
            <th>{{ $t('label.accountId') }}</th>
            <th>{{ $t('label.sourceId') }}</th>
            <th class="name">{{ $t('label.name') }}</th>
            <th class="text-right">{{ $t('label.balance') }}</th>
            <th class="status">{{ $t('label.status') }}</th>
            <th class="n-table-timestamp">{{ $t('label.openingTime') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.profile" :value="row.customerId" />
            </td>
            <td>{{ row.id }}</td>
            <td>{{ row.sourceId }}</td>
            <td class="n-ellipsis" :title="row.name">{{ row.name | hyphen }}</td>
            <td class="n-text-right">
              <n-number :value="row.balance" :scale="row.currencyScale" :currency="row.currency" />
            </td>
            <td v-enums:CustomerAccountStatus="row.status"></td>
            <td>{{ row.openingTime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalAccount from './ModalAccount';
import { Parser, setCurrencyScale } from '@/helpers/operators';
import { ACCOUNT_CSV_URL, fetch } from '@/api/customer/customer-account';
import {CURRENCY_SCALE_NAME} from "@/constant/enums";

export default CustomerView.extend({
  name: 'CustomerAccount',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: 0,
        customerId: null,
        id: null,
        currency: 0,
        status: 0,
        from: this.oneWeekAgo,
        to: this.eod,
      },
      ACCOUNT_CSV_URL,
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = Parser( rows.records, [setCurrencyScale(CURRENCY_SCALE_NAME.CURRENCY)]);
    },
    openModal(model = {}) {
      this.createModal(ModalAccount, { model, on: this });
    }
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 1024px;

  th.status, th.platform, th.company {
    width: 7rem;
  }

  th.rw-action {
    width: 4rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
